/* You can add global styles to this file, and also import other style files */

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "bootstrap/scss/functions";

// 2. Include any default variable overrides here
@import "variables";

/*
// 3. Include remainder of required Bootstrap stylesheets
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";

// 4. Include any optional Bootstrap components as you like
@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/type";
@import "bootstrap/scss/images";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";

@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar";
@import "bootstrap/scss/tables";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
@import "bootstrap/scss/dropdown";
@import "bootstrap/scss/button-group";

@import "bootstrap/scss/card";
@import "bootstrap/scss/pagination";
@import "bootstrap/scss/badge";
@import "bootstrap/scss/alert";
@import "bootstrap/scss/progress";
@import "bootstrap/scss/list-group";
@import "bootstrap/scss/close";
@import "bootstrap/scss/modal";
@import "bootstrap/scss/tooltip";
@import "bootstrap/scss/popover";

// Helpers
@import "bootstrap/scss/helpers";

// Utilities
@import "bootstrap/scss/utilities/api";
*/

/* Importing Bootstrap SCSS file. */
@import "bootstrap/scss/bootstrap";

// 5. Add additional custom code here

html,
body {
    height: 100%;
    overflow-x: hidden;
    box-sizing: border-box;
}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background: transparent;
}

::-webkit-scrollbar-thumb {
    background: $gray-400;
    border-radius: 100px;
}

::-webkit-scrollbar-thumb:hover {
    background: $gray-500;
}

a {
    cursor: pointer;
}

button.btn-plain {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    background-color: inherit;
    color: inherit;
    padding: inherit;
    border: inherit;
    margin: inherit;
    overflow: visible;
    text-transform: inherit;
}

.btn-circle {
    border-radius: 1000px;
}

.btn-xl {
    font-family: $headings-font-family;
    font-size: 2rem;
    padding: 0.5rem 1rem;

    @include media-breakpoint-down(md) {
        font-size: 1.25rem;
    }
}

.btn-back {
    .fa {
        padding-right: 0.5rem;
    }

    @include media-breakpoint-down(md) {
        display: none;
    }
}

h4,
.h4,
h5,
.h5,
h6,
.h6 {
    font-family: $font-family-sans-serif;
    font-weight: 700;
}

.page-header {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    background-color: $gray-200;
    margin-bottom: 0.75rem;
    display: flex;
    align-items: center;

    h1,
    h2 {
        margin-bottom: 0;
    }

    button {
        @include media-breakpoint-down(sm) {
            margin-top: 1rem;
        }
    }

    .btn {
        @include media-breakpoint-down(sm) {
            margin-top: 1rem;
        }
    }
}

.form-control-plaintext {
    font-weight: inherit;
}

.edit {
    padding-left: 2px;
    border: 1px solid transparent;

    &:hover,
    &:focus {
        background-image: url(/assets/images/icons/icon-pencil.svg);
        background-repeat: no-repeat;
        background-position: right 0.75rem top 0.625rem;
        background-size: 1rem;
        border: 1px solid $primary;
    }
}

.text-bold {
    font-weight: 700;
}

img {
    max-width: 100%;
}

.breadcrumb .active {
    font-weight: 700;
}

[type="radio"]:checked,
[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}

.pretty-radio [type="radio"]:checked + label,
.pretty-radio [type="radio"]:not(:checked) + label {
    position: relative;
    padding-left: 2rem;
    margin-right: 2rem;
    margin-bottom: 0.5rem;
    cursor: pointer;
    line-height: 1.4;
    display: inline-block;
}

.pretty-radio [type="radio"]:checked + label:before,
.pretty-radio [type="radio"]:not(:checked) + label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 1.5rem;
    height: 1.5rem;
    border: 1px solid $gray-300;
    border-radius: 100%;
    background: #fff;
}

.pretty-radio [type="radio"]:focus + label:before {
    border-color: $primary;
    box-shadow: 0 0 1rem $primary;
}

.pretty-radio [type="radio"]:checked + label:after,
.pretty-radio [type="radio"]:not(:checked) + label:after {
    content: "";
    width: 0.9rem;
    height: 0.9rem;
    background: $primary;
    position: absolute;
    top: 0.3rem;
    left: 0.3rem;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.pretty-radio [type="radio"]:not(:checked) + label:after,
.pretty-radio [type="radio"]:focus + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}

.pretty-radio [type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

.radio:focus + .tile {
    box-shadow: 0 0 1rem $primary;
    border-color: transparent;
    transition: 0.2s ease-in-out;
}

.radio:checked + .tile {
    box-shadow: 0 0 1rem $primary;
    border: 3px solid $primary;
    transition: 0.2s ease-in-out;
}

.form-floating > .form-control,
.form-floating > .form-select,
.form-floating-height {
    height: calc(3rem + 2px);
}

.form-floating > label {
    padding: 0.75rem !important;
}

.card {
    box-shadow: $card-shadow;
    transition: 0.2s ease-in-out;
    top: 0;
}

.card-hover:hover,
.card-hover:focus {
    box-shadow: 0px 4px 16px 2px rgba(18, 166, 218, 0.2);
    top: -5px;
    transition: 0.2s ease-in-out;
    transform: scale(1.03);
}

.card-bg {
    background-size: cover;
    background-position: center center;
    background-color: $primary;
    color: white;
    text-shadow: $text-shadow;
    text-align: left;
    aspect-ratio: 14 / 9;
    border: 3px solid white;
    position: relative;

    .card-body {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: 0.75rem;
    }

    * {
        z-index: 5;
    }
}

.card-bg::before {
    content: ""; // ::before and ::after both require content
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(180deg, transparent 33%, #000000);
    opacity: 0.3;
    z-index: 1;
    border-radius: subtract($card-border-radius, 3px);
}

.achievement-img {
    max-width: 6.25rem;
}

.ui-img {
    width: 100%;
    max-width: 150px;
    margin-left: auto;
    margin-right: auto;
}

.text-nowrap {
    white-space: nowrap;
}

.blue-green-gradient {
    @include gradient-y($primary, $secondary);
}

.green-yellow-gradient {
    @include gradient-y($secondary, $warning);
}

.blue-red-gradient {
    @include gradient-y($primary, $danger);
}

.invalid {
    color: $danger;
    font-size: 85%;
}

.profile-image {
    background: $gray-200;
    width: 3rem;
    height: 3rem;
    border-radius: 100%;
    border: 2px solid white;
    margin-right: 0.5rem;
}

.profile-image-lg {
    width: 10rem;
    height: 10rem;
}

.collapse-width {
    transition: width 0.35s ease;
    -webkit-transition: width 0.35s ease;
    overflow: hidden;
    white-space: nowrap;
}

.collapsing.collapse-width {
    width: 0 !important;
    transition: width 0.35s ease;
    -webkit-transition: width 0.35s ease;
    height: 100%;
    overflow: hidden;
}

.sidebar {
    height: 100%;
    width: 250px !important;
}

.modal-header h1,
.modal-header h2,
.modal-header h3,
.modal-header h4,
.modal-header h5,
.modal-header h6 {
    margin-bottom: 0;
}

.modal-header,
.toast-header {
    border: 0;
    padding-bottom: 0;
}

.modal-content,
.modal-content * {
    transition: all 0.2s ease;
}

.unset-modal .modal-content {
    background-color: transparent;
    border: none;
    box-shadow: none;
}

.invert {
    filter: invert(100%);
}

.icon-red {
    filter: invert(80%) sepia(96%) saturate(7018%) hue-rotate(358deg) brightness(99%) contrast(116%);
}

.text-icon {
    height: 1em;
    width: auto;
    display: inline-block;
    position: relative;
    top: -0.1em;
    margin: 0.1em;
}

.image-preload {
    height: 1px;
    width: 1px;
    visibility: hidden;
    position: absolute;
    left: -5000px;
    top: -5000px;
}

.hide-shell {
    display: none !important;
}

.popover-body {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.lottie {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    z-index: 9999;
    pointer-events: none;
}

.main {
    > .container-fluid {
        height: 100%;
    }
}

.container-fluid.full-width {
    padding-left: 0;
    padding-right: 0;

    .row:not(.row .row) {
        margin-left: 0;
        margin-right: 0;
    }

    @include media-breakpoint-up(md) {
        .row.adhere-left-edge {
            @include make-row();
            margin-left: -0.75rem !important;
            padding: 0;
        }
    }
}

.alert-dot {
    width: 1.75rem;
    height: 1.75rem;
    display: flex;
    background-color: $warning;
    padding: 0.25rem;
    border-radius: 100rem;
    position: absolute;
    top: 10%;
    left: 65%;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 12pt;
    z-index: 9000;
    color: black;
    box-shadow: $box-shadow;

    &.alert-dot-empty {
        top: 40%;
        width: 1rem;
        height: 1rem;
    }

}



.main-sidebar-container {
    li {
        .alert-dot {
            top: 22%;
            left: 70%;

            @include media-breakpoint-down(md) {
                top: 17%;
                left: 80%;
            }
        }
    }
}

.bg-ice {
    background-color: #368BC1;
}