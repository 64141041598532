@use "sass:math";

$primary: #1ca0d1;
$secondary: #68bd45;
$success: #59a13b;
$info: #4dbce3;
$warning: #ffae35;
$danger: #eb5050;

$font-family-sans-serif: "Open Sans", Arial, sans-serif;
$headings-font-family: "Bubblegum Sans", Arial, sans-serif;

$min-contrast-ratio: 2;

$card-border-width: 0;
$card-border-radius: 1rem;
$card-inner-border-radius: subtract($card-border-radius, $card-border-width);
$card-height: 100%;
$card-shadow: 0px 4px 12px rgb(12 61 79 / 12%);
@mixin dropshadow {
    filter: drop-shadow(0px 4px 12px rgb(12 61 79 / 12%));
}

$text-shadow: 0 0.15em 0.2em rgb(0 0 0 / 50%), 0 0 0.5em rgb(0 0 0 / 30%);
$text-shadow-light: 0 0 0.5em rgb(255 255 255 / 50%), 0 0 0.75em rgb(255 255 255);

@function pxtoRem($value) {
    $remValue: math.div($value, 16) + rem;
    @return $remValue;
}
